<template>
  <div class="history-container">
    <!-- {{incomingHistory}} -->
    <!-- {{compHistoryArr}} -->
    <div v-if="hasHistory" ref="element" class style="margin-bottom: 100px">
      <div class="timeline" style="padding-left: 20px">
        <header class="timeline-header">
          <div v-if="showRefresh">
            <b-tooltip label="Click to refresh." type="is-dark" position="is-right">
              <span @click="refresh">
                <b-icon pack="fas" icon="sync-alt" :custom-class="spin"></b-icon>
              </span>
            </b-tooltip>
          </div>
        </header>
        <div class="grey-dot-history-top"></div>
        <div v-for="(item, index) in historyArr" :key="index" :label="item.action" class="timeline-item">
          <div class="history-time-tag">
            <span class="tag is-rounded is-yellow">{{
              item.time | relativeTime
            }}</span>
          </div>

          <div class="timeline-content">
            <!-- <div class="tags are-small has-addons">
              <span class="tag is-rajivsgreen">Time</span>
              <span class="tag">{{ localTime(item.time) }}</span>
            </div> -->
            <div id="history-array">
              <b-collapse class="card collaps-card" animation="none" aria-id="contentIdForA11y3"
                :open="index === 0 ? true : false">
                <div slot="trigger" slot-scope="props" class="card-header new-collable" role="button"
                  aria-controls="contentIdForA11y3">
                  <div class="card-header-title">
                    <div class="header-icon-container">
                      <figure class="image">
                        <img v-if="item.user == 'System'" class="is-rounded" src="../../public/icons/Frame (1).png" />
                        <avatar v-else :size="44" :username="item.user" :src="item.user_photo"></avatar>
                      </figure>
                    </div>
                    <div class="drop-down-headed">
                      <div style="width: 100%">
                        <div>
                          {{ item.user }}
                        </div>
                        <div>
                          <span class="subtitle is-6">{{ item.role.toUpperCase() }}</span>
                        </div>
                      </div>
                      <div class="center on-end" style="width: 65%; position: relative; left: 23px">
                        <span class="tag is-info is-rounded" style="width: inherit">{{ item.action | humanize_string |
                          title_case }}</span>
                      </div>
                    </div>
                  </div>

                  &nbsp;
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <div class>
                      <br />
                      <div class="columns">
                        <div class="column">
                          <!-- <div class="field">
                            <div class="is-normal">
                              <label class="label">State</label>
                            </div>
                            <p class="control is-expanded">
                              <input
                                class="input"
                                :value="item.state"
                                disabled
                              />
                            </p>
                          </div> -->
                          <justLable lableName="State :" :lableValue="item.state" icon="filedoler.svg">
                          </justLable>
                        </div>
                        <div class="column">
                          <!-- <div class="field">
                            <div class="is-normal">
                              <label class="label">Role</label>
                            </div>
                            <div class="field-body">
                              <div class="field">
                                <p class="control is-expanded">
                                  <input
                                    class="input"
                                    :value="item.role"
                                    disabled
                                  />
                                </p>
                              </div>
                            </div>
                          </div> -->
                          <justLable lableName="Role :" :lableValue="item.role" icon="filedoler.svg">
                          </justLable>
                        </div>
                      </div>
                      <!-- <div v-if="showDetails" class="field">
                        <div class="field-label"></div>
                        <div class="field-body">
                          <div class="field is-expanded">
                            <div class="field has-addons">
                              <p class="control">
                                <a class="button is-static">TXID</a>
                              </p>
                              <p class="control is-expanded">
                                <input
                                  class="input"
                                  type="tel"
                                  :value="item.txid"
                                  readonly
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div class="columns">
                        <div class="column">
                          <justLable lableName="TXID :" :lableValue="doTrancate(item.txid, 20, '...')"
                            icon="filedoler.svg">
                          </justLable>
                        </div>
                        <div v-if="showDetails" class="column">
                          <!-- <div class="field">
                            <div class="is-normal">
                              <label class="label">Hash</label>
                            </div>
                            <p class="control is-expanded">
                              <input
                                class="input"
                                :value="item.data_hash || 'NA'"
                                disabled
                              />
                            </p>
                          </div> -->
                          <justLable lableName="Hash :" :lableValue="
                            doTrancate(item.data_hash || 'NA', 20, '...')
                          " icon="filedoler.svg">
                          </justLable>
                        </div>
                      </div>
                      <div class="column">
                        <div class="field">
                          <label class="label">Comments</label>
                          <div class="control just-line">
                            <article v-for="(commentItem, i) in item.comments" :key="i" class="media">
                              <div class="media-content">
                                <div class="content">
                                  <div :class="[
                                    {
                                      'has-text-right':
                                        isUserYou(commentItem),
                                    },
                                  ]">
                                    <div style="margin-bottom: 10px">
                                      <small v-if="isUserYou(commentItem)">
                                        {{
                                          commentItem.timestamp | time_humanized
                                        }}
                                      </small>
                                      <strong v-if="isUserYou(commentItem)">You</strong>
                                      <strong v-else>{{
                                        commentItem.user
                                      }}</strong>
                                      <small v-if="!isUserYou(commentItem)">
                                        {{
                                          commentItem.timestamp | time_humanized
                                        }}
                                      </small>
                                    </div>

                                    <div style="margin-bottom: 10px">
                                      <p :class="[
                                        {
                                          'comment-onleft':
                                            !isUserYou(commentItem),
                                        },
                                        {
                                          'comment-onright':
                                            isUserYou(commentItem),
                                        },
                                      ]">
                                        {{ commentItem.comment }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </article>
                            <!-- <div class="field ">
                              <p class=" is-expanded ">
                                <input
                                  class="input is-rounded"
                                  type="text"
                                  placeholder="Your comment"
                                  v-model="history.comment[index]"
                                />
                              </p>
                              <p class="">
                                <a
                                  class="button"
                                  @click="
                                    sendComment(
                                      item.txid,
                                      history.comment[index],
                                      index
                                    )
                                  "
                                >
                                  Send
                                </a>
                              </p>
                            </div> -->
                            <div class="control">
                              <input class="input is-rounded" type="text" placeholder="Your comment"
                                v-model="history.comment[index]" />
                              <span class="is-small is-right">
                                <button class="button button-in-input-2 is-yellow is-rounded is-small" @click="
                                  sendComment(
                                    item.txid,
                                    history.comment[index],
                                    index
                                  )
                                ">
                                  Send
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <div class="center time-box-history">
                            <span class="time-contaner-history">{{
                              item.time
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="card-container">
      <section v-if="!isLoading" ref="element" class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="emoticon-confused-outline" size="is-large"></b-icon>
          </p>
          <p>Nothing to show!</p>
        </div>
      </section>
      <section ref="element" class="section center-imp" style="position: relative">
        <div>
          <b-loading :is-full-page="false" :active.sync="isLoadingHistory"></b-loading>
          <strong v-if="noOpr">{{ noOpr }}</strong>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import moment from "moment";
import justLable from "./justLable.vue";
import { globalFunction } from "../services/global.function";

export default {
  data() {
    return {
      historyArr: null,
      spin: "",
      isLoading: true,
      isFullPage: false,
      hasHistory: false,
      showDetails: true,
      isLoadingHistory: true,
      noOpr: null,
      inactive: false,
      comment: null,
      userDetails: null,
      history: {
        comment: [],
      },
    };
  },
  components: {
    justLable,
    Avatar,
  },
  props: {
    transactions: Array,
  },
  mounted() { },
  created: function () {
    this.init();
  },
  computed: {
    showRefresh() {
      return this.$store.state.isRefresh;
    },
    authFlowObject() {
      return this.$store.getters.authFlowObject;
    },
    centrifugo() {
      return this.$store.getters.centrifugoObject;
    },
    selectedWorkFlowTxns() {
      return this.transactions;
    },
  },
  watch: {
    incomingHistory(val) {
      console.log("Console log : incomingHistory -> val", val);
    },
    selectedWorkFlowTxns: {
      handler(newValue, oldValue) {
        this.getHistory(newValue);
        // if (this.subFolderItems.length > 0) this.slideup();
      },
      deep: true,
    },
  },
  methods: {
    init: async function () {
      await this.getHistory(this.selectedWorkFlowTxns);
    },
    isUserYou(itemData) {
      // console.log("itemData", itemData);

      this.userDetails = this.$store.getters.getUserDetails;
      // console.log("user details", this.userDetails);
      if (itemData.user == this.userDetails.profile.full_name) {
        return true;
      }
      return false;
    },
    socketData(passedData) {
      console.log("Console log : mounted -> passedData", passedData);
    },
    localTime: function (utcTime) {
      return moment
        .utc(utcTime, "DD MMM YYYY HH:mm:ss")
        .local()
        .calendar({ sameElse: "DD MMM YYYY HH:mm:ss" });
    },
    sendComment: async function (txid, comment, historyIndex) {
      let workflowDetails = this.$store.getters.getWorkFlowInfo;
      console.log("Send comment detials", workflowDetails, txid, comment);
      // this.historyArr[historyIndex].comments.push({
      //   timestamp: new Date(),
      //   comment: comment,
      //   user: this.userDetails.profile.full_name,
      // });
      let workFlowID = workflowDetails.workflowId;
      // console.log("pushed to comment", this.historyArr[historyIndex].comments);

      await this.$store.dispatch("SEND_COMMENT", { txid, workFlowID, comment });
      this.history.comment = [];
    },
    openLoading() {
      console.log(
        "Console log : openLoading -> this.isFullPage",
        this.isFullPage
      );
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el,
      });
    },
    getHistory: async function (selectTxns) {
      this.isLoadingHistory = true;
      let wid = this.$store.getters.getWorkFlowID;
      let txns = selectTxns;
      if (txns.length > 0) {
        // this.historyArr = txns.reverse();
        this.historyArr = txns;
        if (this.historyArr.length === 0) {
          console.log(
            "Console log : this.historyArr.length",
            this.historyArr.length
          );
          this.isLoadingHistory = false;
          this.hasHistory = false;
          this.noOpr = "No Transactions yet for the selected WorkFlow!";
        } else {
          this.hasHistory = true;
          this.isLoadingHistory = false;
        }
      } else {
        this.isLoadingHistory = false;
        this.noOpr = "No WorkFlow selected!";
      }
    },
    doTrancate(string, length, suffix) {
      if (string.length <= length) {
        return string;
      } else {
        return string.slice(0, length) + suffix;
      }
    },
    getIcon: function (action) {
      switch (action) {
        case "register":
          return "file-check-outline";
          break;
        case "std-sign":
          return "signature-freehand";
          break;

        default:
          break;
      }
    },
    refresh: async function () {
      this.spin = "fa-spin";
      await this.getHistory(this.selectedWorkFlowTxns);
      this.spin = "";
    },

    // added 15-march-2021 (Rajiv Chandran)
    // Function to convert action verbs into present tense. Necessary to deprecate use of past tense in history.

    setPresentTense: function (selectedValue) {
      var result = `unknown (${selectedValue})`;

      // Action Verbs are retrieved from the store and stored in allVerbs variable.
      var allVerbs = this.$store.getters.getVerbList;

      // Special handling for 2 verbs not available in store. "permissions" and "finalize".
      // The "default" case code snippet handles (almost) all others verbs except
      //    for "permissions" and " finalized" where it assigns empty string when match is received
      //
      // IMPORTANT -- look here in case history html shows something like "[] requested by ...."

      switch (selectedValue.toLowerCase()) {
        case "permissions":
          result = "permissions";
          break;
        case "finalized":
          result = "finalize";
          break;
        case "registered":
          result = "register";
          break;
        case "saved":
          result = "save";
          break;
        default:
          allVerbs.forEach((item) => {
            if (selectedValue.toLowerCase() === item.Simple_Past) {
              result = item.Base_Form;
            }
          });
      }
      return result;
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
  beforeDestroy() {
    console.log("destroying at history", this.$data);
    this.inactive = true;
  },
  events: {},
};
</script>
<style lang="scss">
.step-marker .icon {
  display: flex !important;
  justify-items: center;
}
</style>
